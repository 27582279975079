<template>
  <iframe :src="reportLink" />
</template>

<script>
import { mapGetters } from 'vuex'
import appConfig from '@core/app-config'

export default {
  name: 'ReportPage',

  props: {
    test: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters('app', {
      apiAddr: 'apiAddr',
    }),
    reportLink() {
      const appConfigProject = appConfig.find(app => app.projectName === this.$route.meta.project)
      return `${appConfigProject.reportUPR}${this.test}`
    },
  },
}
</script>

<style scoped>
iframe {
  width: 100%;
  height: calc(100vh - 170px);
  border: 0;
}
</style>
